.text-link {
	$item: &;

	@include small-bold($blue);

	text-decoration: none;
	cursor: pointer;

	&__icon {
		margin-right: 5px;
		transition: transform 0.3s;
	}

	&__label {
		display: inline-block;
	}

	&--base {
		font-size: 16px;
	}

	&--large {
		@include medium-bold($blue);
	}

	&--margin {
		display: block;
		margin: 10px 0 25px 0;
	}

	&:focus {
		text-decoration: underline;

		#{$item} {
			&__label {
				text-decoration: underline;
			}
		}
	}

	&:hover {
		#{$item} {
			&__icon {
				&[class*='chevron'],
				&[class*='arrow'] {
					transform: translateX(3px);
				}
			}
		}
	}
}