textarea {
	width: 100%;
	display: block;
	padding: 10px;
	line-height: 20px;
	font-size: 16px;
	letter-spacing: 0.1px;
	resize: none;

	&:focus,
	&:active {
		outline: none;
	}

	&[readonly] {
		border-color: $grey;
		background-color: $contrast-grey;
		cursor: not-allowed;
	}
}