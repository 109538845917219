h1 {
	@include heading-large($blue);
}

h2 {
	@include heading-medium($blue);
}

h3 {
	@include heading-small($blue);
}

// h4 {

// }

// h5 {

// }

// h6 {

// }