.header {
  $height: 72px;
  $padding: 8px;

  $header: &;

  background-color: $white;
  padding: $padding 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  box-shadow: 0 10px 10px 0 rgba($grey, 0.15);

  &__logo {
    $size: $height - ($padding * 2);

    height: $size;
    width: $size;
    display: block;
  }

  &__home-link {
    display: block;
  }

  &__nav {
    $block: &;
    $containerPadding: 40px;

    &-container {
      background-color: $blue;
      height: calc(100% - #{$height});
      left: 0;
      padding: $containerPadding;
      position: fixed;
      top: $height;
      transform: translateX(100%);
      transition: transform 0.3s;
      width: 100%;
      z-index: 1;

      @include media-breakpoint-up(md) {
        background-color: $white;
        height: auto;
        left: auto;
        padding: 0;
        position: relative;
        top: auto;
        transform: translateX(0);
      }

      &--active {
        transform: translateX(0);

        #{$block} {
          &-item {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      justify-content: center;
    }

    &-link {
      color: $white;
      text-decoration: none;
      padding-left: 10px;

      @include media-breakpoint-up(md) {
        color: $blue;
        opacity: 0.5;
        padding-left: 0;
      }

      &--logout {
        opacity: 1;
        margin-left: -10px;
        cursor:pointer;

        @include media-breakpoint-up(md) {
          margin-left: 10px;
        }
      }
    }

    &-item {
      $itemHeight: 20px;

      @include medium-bold($white);

      margin-bottom: $containerPadding;
      opacity: 0;
      transform: translateY(10px);
      width: 100%;
      transition: opacity 0.3s;

      @include media-breakpoint-up(md) {
        box-sizing: content-box;
        height: $itemHeight;
        margin-bottom: 0;
        padding: (($height - ($padding * 2) - $itemHeight) / 2) 20px;
        width: auto;
        opacity: 1;
        transform: none;
        position: relative;
      }

      &:before {
        $itemHeight: 4px;

        @include media-breakpoint-up(md) {
          content: " ";
          position: absolute;
          bottom: $padding * -1;
          left: 0;
          width: 100%;
          height: $itemHeight;
          background-color: $green;
          transform: translateY($itemHeight);
          opacity: 0;
          transition: opacity 0.3s, transform 0.3s;
        }
      }

      svg {
        &:not(.user-alt) {
          transition: transform 0.15s;

            @include media-breakpoint-up(md) {
              display: none;
            }
          }
        }


      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition: opacity 0.3s #{0.05 + (0.1 * $i)}s, transform 0.3s #{0.05 + (0.1 * $i)}s;
        }
      }

      &--active {
        #{$block} {
          &-link {
            color: $contrast-blue;
            opacity: 1;

            @include media-breakpoint-up(md) {
              color: $blue;
            }
          }
        }

        svg {
          &.chevron-right {
            fill: $contrast-blue;
          }
        }

        #{$header} {
          &__logout {
            color: $contrast-blue;

            @include media-breakpoint-up(md) {
              color: $blue;
            }
          }
        }

        &:before {
          @include media-breakpoint-up(md) {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      &--user {
				font-size: 14px;
        font-weight: 600;
        padding-right: 0;

				display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
          position: absolute;
          top: 0;
          width: auto;
          right: calc(-25% - 15px);
        }

        @include media-breakpoint-up(lg) {
          right: calc(-50% - 15px);
        }

        span {
          color: $blue;
          display: block;
        }

        svg.logout-chevron {
          display: unset;
          margin-left: 10px;
        }

        svg {
          &:not(.user-alt) {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &__avatar-mockup {
    $size: 26px;

    background-color: $blue;
    border-radius: 50%;
    height: $size;
    margin: 0;
    width: $size;
    transform: translate(0, -2px); // To visually (!) center element
    position: relative;
    fill: #fff;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      overflow: hidden;
    }
  }

  &__logout {
    @include medium-bold($blue);

    @include media-breakpoint-up(md) {
      margin-left: 10px;
    }

    svg {
      margin-right: 10px;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__hamburger {
    $block: &;

    margin: 20px 0;
    cursor: pointer;

    &-line {
      width: 14px;
      height: 2px;
      background-color: $blue;
      opacity: 1;
      transition: transform 0.3s, opacity 0.3s;

      &:nth-child(2) {
        margin: 3px 0;
      }
    }

    &-label {
      font-weight: 600;
      color: $blue;
      margin: 17px 0 17px 10px;
      cursor: pointer;
    }

    &--active {
      #{$block} {
        &-line {
          &:nth-child(1) {
            transform: rotate(45deg) translate(3px, 4px);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(-45deg) translate(3px, -4px);
          }
        }
      }
    }
  }

  &__back-button {
    margin-bottom: 2px; // To visually (!) center element
  }
}

.user-menu {
  display: block;
  position:absolute;
  background-color: $white;
  padding: 30px 40px 30px 30px;
  color: $blue;
  top: $header-height - 10px;
}

.format-user-email {
  max-width: 300px;
}
