.jumbotron {
	background-color: $beige;
	position: relative;
	padding: 25px 0;

	@include media-breakpoint-up(md) {
		padding: 50px 0;
	}

	&__title {
		@include heading-medium($blue);

		text-align: left;
		padding-left: 10px;
		position: relative;
		z-index: 1;

		@include media-breakpoint-up(md) {
			@include heading-large($blue);
		}

		&--small-mobile {
			@include heading-small($blue);

			@include media-breakpoint-up(md) {
				@include heading-large($blue);
			}
		}
	}

	&__back-button {
		@include medium-semi-bold($blue);

		font-size: 16px;
		text-decoration: none;
		position: absolute;
		top: -72px;
		left: 15px;
		z-index: 1;

		@include media-breakpoint-up(md) {
			@include medium-semi-bold($white);

			position: relative;
			top: auto;
			left: auto;
		}

		&-icon {
			margin-right: 10px;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	&__tagline {
		width: 100%;
		text-align: center;
		color: $white;
		margin-top: -10px;
		margin-bottom: 30px;
		position: relative;
		z-index: 1;
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		img {
			position: absolute;
			width: 100%;
			left: 0;
			height: auto;
			top: 50%;
			transform: translateY(-55%);
			opacity: 0.9;
		}
	}

	&--overlap {
		padding-bottom: 150px;
		margin-bottom: -125px;
	}
}
