.ltri-cookie-notice {
  background-color: rgb(24, 13, 85);
  color: rgb(255, 255, 255);
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 99;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 32px;
  max-width: 1097px;
  box-sizing: border-box;
  transform: translateY(100%);
  visibility: hidden;
  transition: transform 0.4s ease 0s, visibility 0.4s ease 0s;
  font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
  font-size: 16px;
  line-height: 28px;
  -webkit-font-smoothing: antialiased;
}

.ltri-cookie-notice--visible {
  transform: translateY(0px);
  visibility: visible;
}

@media (min-width: 768px) {
  .ltri-cookie-notice {
    margin: 0 66px;
  }
}

.ltri-cookie-notice__title {
  font-size: 16px;
  line-height: 28px;
  margin: 0 0 5px;
  color: white;
}

@media (min-width: 768px) {
  .ltri-cookie-notice__actions {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.ltri-cookie-notice__actions {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: center;
}

@media (min-width: 768px) {
  .ltri-cookie-notice__actions a {
    margin-bottom: 0;
    margin-right: 24px;
  }
}

.ltri-cookie-notice__actions a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}

.ltri-button {
  font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
  border: 0;
  appearance: none;
  font-size: 16px;
  font-weight: 600;
  min-height: 40px;
  box-sizing: border-box;
  color: white;
  padding: 9px 17px;
  border-radius: 2px;
  background-color: rgb(127, 206, 108);
  display: inherit;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  transition: color 200ms ease-in-out 0s, background-color 200ms ease-in-out 0s;
  line-height: normal;
}

@media (min-width: 768px) {
  .ltri-button {
    min-height: 47px;
    padding: 13px 17px;
  }
}
