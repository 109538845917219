.tab-container {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  padding: 0;
  margin: -45px -45px 0;
  align-items: center;
}

.tab-container-mid-officer {
  grid-template-columns: repeat(1, 1fr);
}

.tab {
  padding: 20px 45px;
  flex: 1 1 25%;
  text-align: center;
  border-bottom: 4px solid transparent;

  &:last-child {
    border-right: none;
  }

  &--active {
    border-bottom: 4px solid $green;
  }

  &__link {
    text-decoration: none;
    color: $blue;

    &:active,
    &:focus {
      text-decoration: none;
    }
  }
}
