.button {
	display: inline-block;
	cursor: pointer;
	text-align: center;

	&:active,
	&:focus {
		text-decoration: none;
		outline: none;
	}

	&__icon {
		margin-right: 5px;
	}

	&__container {
		$margin: 7px;

		> * {
			margin-bottom: $margin;

			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		&--padding {
			margin-top: $margin;
			margin-bottom: $margin / 2;
		}

		&--desktop {
			> * {
				@include media-breakpoint-up(md) {
					margin-bottom: $margin;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&--ghost {
		@include medium-bold($blue);

		padding: 13px 24px;
		text-decoration: none;
		border: 1px solid $blue-neon;
		border-radius: 30px;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s;

		&:hover {
			background-color: rgba($blue-neon, 0.2);
		}

		&:focus {
			border: 1px solid $blue;
		}

		&:active {
			background-color: rgba($blue-neon, 0.3);
		}
	}

	&--solid {
		@include medium-bold($blue);

		color: $white;
		background-color: $blue;
		padding: 13px 24px;
		text-decoration: none;
		border: 1px solid $blue-neon;
		border-radius: 30px;
		transition: background-color 0.2s, color 0.2s;

		&:hover {
			background-color: darken($blue, 8%);
			color: $white;
		}

		&:active {
			background-color: darken($blue, 12%);
		}

		&:focus {
			color: lighten($blue-neon, 15%);
		}
	}

	&--custom {
		@include medium-bold($blue);

		color: $white;
		background-color: $blue;
		padding: 13px 24px;
		text-decoration: none;
		border: 1px solid $blue-neon;
		border-radius: 5px;
		transition: background-color 0.2s, color 0.2s;

		&:hover {
			background-color: darken($blue, 8%);
			color: $white;
		}

		&:active {
			background-color: darken($blue, 12%);
		}

		&:focus {
			color: lighten($blue-neon, 15%);
		}
	}

	&--custom-terug {
		@include medium-bold($green);

		color: $white;
		background-color: $green;
		padding: 13px 24px;
		text-decoration: none;
		border: 1px solid $green-highlight;
		border-radius: 5px;
		transition: background-color 0.2s, color 0.2s;

		&:hover {
			background-color: darken($green, 8%);
			color: $white;
		}

		&:active {
			background-color: darken($green, 12%);
		}

		&:focus {
			color: lighten($green-highlight, 15%);
		}
	}

	&--full-width {
		width: 100%;
	}

  &--full-width-desktop {
    width: 100%;
    margin-bottom: 10px;
  }

	&--full-width-mobile {
		@include media-breakpoint-down(lg) {
			width: 100%;
			margin-left: 0 !important;
			margin-right: 0;
			margin-bottom: 10px;
			text-align: center;
		}
	}

	&--margin {
		@include media-breakpoint-up(md) {
			margin: 1.1em 0;
		}
	}

	&--vertical {
		display: block;

		+ .button {
			margin-left: 0 !important; // overrule horizontal margin
			margin-top: 10px;
		}
	}

	&--disabled,
	&--disabled:hover {
		color: $grey;
		border-color: $light-grey;
		background-color: $white;
		cursor: unset;
	}

	+ .button {
		margin-left: 5px;
	}
}
