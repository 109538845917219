.alert {
	$offset: 15px;

	@include medium-bold($white);

	position: fixed;
	background-color: $green;
	padding: 18px 20px;
	border-radius: 12px;
	bottom: $offset;
	right: $offset;
	z-index: 100;

	&__icon {
		margin-right: 5px;
		font-size: 22px;
		transform: translateY(2px);
	}

  &.error {
    background-color: $red;
  }

  &.partial_error {
    background-color: #FFA500;
  }
}
