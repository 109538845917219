.background-gradient {
	width: 100%;
	max-height: 100%;
	min-height: 600px;
	top: 0;
	height: 100%;
	position: fixed;
	overflow: hidden;
	background-color: $blue;

	&:after {
		content: " ";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		padding-bottom: 50%;
		background-image: linear-gradient(-180deg, rgba($blue, 0) 0%, $blue 100%);

		@include media-breakpoint-up(md) {
			background-image: linear-gradient(-180deg, rgba($blue, 0) 0%, $blue 70%);
		}
	}

	&__image {
		display: block;
		width: 100%;
		transform: translateY(-10%);

		@include media-breakpoint-up(md) {
			transform: translateY(-20%);
		}
	}
}
