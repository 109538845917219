.table {
  $block: &;
  $padding: 20px;
  display: block;
  width: 100%;

  @include media-breakpoint-up(md) {
    display: table;
    border-collapse: collapse;
    table-layout: fixed;
    $padding: 25px;
  }

  &__row {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ($padding * 0.9) $padding;

    &:nth-child(odd) {
      background-color: $contrast-grey;

      @include media-breakpoint-up(md) {
        background-color: $white;
      }
    }

    &:nth-child(even) {
      @include media-breakpoint-up(md) {
        background-color: $contrast-grey;
      }
    }

    @include media-breakpoint-up(md) {
      display: table-row;
    }
  }

  &__head {
    display: none;

    @include media-breakpoint-up(md) {
      display: table-header-group;
      margin-top: $padding * -1; // Reset top padding of card
    }

    #{$block} {
      &__row {
        background-color: $white;
      }

      &__column {
        position: relative;

        .input {
          &__element {
            padding-left: 35px;
          }
        }

        #{$block} {
          &__label {
            @include medium-bold($blue);

            width: 100%;
            text-align: left;

            @include media-breakpoint-up(md) {
              font-size: 12px;
            }

            @include media-breakpoint-up(lg) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  &__column {
    @include small-normal($dark-grey);

    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 8px;

    @include media-breakpoint-up(md) {
      display: table-cell;
      width: auto;
      font-size: 14px;
      margin-bottom: 0;
      padding: ($padding * 0.9) $padding;
    }

    @include media-breakpoint-up(lg) {
      font-size: 15px;
    }

    span {
      @include small-bold($blue);

      display: block;
      width: 48%;

      &:not(.table__label) {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    &--floating-link {
      @include media-breakpoint-up(md) {
        position: absolute;
        right: 20px;
        width: auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--inline-label {
      &:before {
        @include small-bold($blue);

        content: attr(data-label);
        width: 48%;
        display: inline-block;

        @include media-breakpoint-up(md) {
          content: none;
        }
      }
    }

    &--inline-bottom {
      &:before {
        @include small-bold($blue);
        content: attr(data-label);
        width: 48%;
        display: inline-block;

        @include media-breakpoint-up(md) {
          content: none;
        }
      }
      border-top:1px solid #000;
      font-weight: bold;
    }

    &--header {
      overflow: visible;
      padding-right: 0;
    }

    &--no-overflow {
      overflow: auto;
      text-overflow: initial;
      white-space: normal;
      word-break: break-all;
    }
  }

  &__transaction-column {
    white-space: normal;
    text-overflow: initial;
  }
  &__link {
    display: block;
    text-align: right;
    width: 100% !important; // Overrule style of span element, if using span

    &--left {
      text-align: left;
    }
  }

  &__search-more-message {
    margin-top: 16px;
    width: 100%;
    text-align: center;
  }

  &__sort {
    color: $green;
    margin-left: 5px;
    transform: translate(5px);
  }

  &__search {
    margin-bottom: 5px;
    margin-right: 0;
  }

  &__label {
    margin-bottom: 35px;

    &--less-margin {
      margin-bottom: 5px;
    }
  }

  &__footer {
    margin: 15px 0;

    @include media-breakpoint-up(md) {
      padding-top: 15px;
    }

    &-content {
      color: $grey;
      margin: 4px 0;

      @include media-breakpoint-up(md) {
        margin: 14px 0;
      }
    }

    &-pagination {

    }

    &-select {

    }
  }

  &__check {
    color: $green;
  }

  &__times {
    color: $red;
  }

  &__lazy-loading {
    @include medium-bold($blue);
    text-align: center;
    width: 100%;
    margin: 35px 0 5px 0;
    display: block;
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: underline;
    }

    &-icon {
      margin-right: 10px;
    }
  }

  &--plain {
    #{$block} {
      &__row {
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }

  &--no-side-padding {
    #{$block} {
      &__row {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &--margin-top {
    margin-top: 25px;
  }

  &--first-column-wide {
    #{$block} {
      &__row {
        #{$block} {
          &__column {
            &:nth-child(1) {
              @include media-breakpoint-up(md) {
                width: 200%;
              }
            }
          }
        }
      }
    }
  }

  &--second-column-wide {
    #{$block} {
      &__row {
        #{$block} {
          &__column {
            &:nth-child(2) {
              @include media-breakpoint-up(md) {
                width: 200%;
              }
            }
          }
        }
      }
    }
  }

  &--slim {
    #{$block} {
      &__column {
        padding: 10px;
      }
    }
  }

  .card > & {
    margin-left: $padding * -1; // Reset left padding of card
    margin-right: $padding * -1; // Reset right padding of card
    width: calc(100% + #{$padding * 2});

    &:first-child {
      margin-top: $padding * -1; // Reset top padding of card, if no element above
    }

    &:last-child {
      margin-bottom: $padding * -1; // Reset bottom padding of card, if no element underneath
    }
  }

  * + & {
    margin-top: $padding; // Add margin above table if another element is directly above table
  }
}
