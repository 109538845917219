@mixin heading-small($color) {
	color: $color;
	font-size: 26px;
	font-weight: bold;
}

@mixin heading-medium($color) {
	color: $color;
	font-size: 30px;
	font-weight: bold;
}

@mixin heading-large($color) {
	color: $color;
	font-size: 38px;
	font-weight: bold;
}