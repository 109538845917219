.field {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  &__label {
    margin-right: 1rem;
    min-width: 100%;
    max-width: 40%;
    flex: 1 0 auto;

    @include media-breakpoint-up(md) {
      min-width: 40%;
    }
  }

  &__input {
    display: flex;
    flex: 1 1 auto;
    position: relative;

    &--full-width {
      width: 100%;
      margin: 1rem 0;
    }
  }

  &__remove {
    flex: 0 0 1%;
    margin-left: 1rem;

    &:hover {
      .text-link {
        color: darken($blue, 8%);
        transition: color .3s ease-out;
      }
    }
  }

  &__error {
    width: 100%;
  }
}
