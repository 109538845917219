:root {
  --reach-accordion: 1;
  --reach-dialog: 1;
}

/* ITCSS sections */

@import 'settings/_all';
@import 'tools/_all';
@import 'generic/_all';
@import 'elements/_all';
@import 'objects/_all';
@import 'components/_all';
@import 'utilities/_all';
