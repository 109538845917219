.card {
	padding: 20px;
	border-radius: 0;
	box-shadow: 0 4px 40px 0 rgba(0,0,0,0.05);
	display: block;
	margin: 0 -15px;
	width: calc(100% + 30px);
	overflow: hidden;
	position: relative;

	&__spacing {
		display: block;
		height: 25px;
		width: 100%;

		@include media-breakpoint-up(md) {
			height: 40px;
		}
	}

	&__close {
		$padding: 20px;

		@include huge-normal($blue);

		position: absolute;
		cursor: pointer;
		top: $padding;
		right: $padding;

		&--modal {
			top: 0;
			right: 15px;

			@include media-breakpoint-up(md) {
				top: -10px;
				right: -35px;
			}
		}
	}

	> * {
		&:first-of-type {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				&:first-of-type {
					margin-top: 3px;
					margin-bottom: 40px;
				}
			}
		}
	}

	> div {
		> * {
			&:last-of-type {
        ol,
				ul {
					&:last-of-type {
						margin-bottom: 16px;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		width: 100%;
		margin: 0;
		border-radius: 15px;
		padding: 25px;
		margin-bottom: 40px;
	}

	&--white {
		background-color: $white;
	}

	&--padding {
		@include media-breakpoint-up(md) {
			padding: 45px;
		}

	}

	&--offset-top {
		margin-top: 150px;
	}

	&--small {
		padding: 35px;

		> * {
			&:first-of-type {
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					margin-bottom: 30px !important; // overrule regular margin-bottom
				}
			}
		}
	}
}


