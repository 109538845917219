.wizard {
  $block: &;
  $size: 30px;
  list-style: none;
  counter-reset: var(--wizard-name);
  margin: 0;
  padding: 0;

  > #{$block}__step {
    border: none;
    counter-increment: var(--wizard-name);
  }

  &__trigger {

    #{$block}__counter {
      position: relative;
      display: inline-block;
      width: 30px;
      margin-right: 12px;
      height: 30px;

      &::before {
        content: counter(var(--wizard-name));
        @include small-normal($white);

        position: relative;
        width: $size;
        height: $size;
        background-color: $blue;
        display: inline-block;
        border-radius: 100%;
        text-align: center;
        line-height: $size;
        margin-right: 10px;

        @include media-breakpoint-up(md) {
          @include medium-normal($white);
        }
      }
    }

    &--disabled {
      #{$block}__counter {
        &::before {
          background-color: $grey;
        }
      }
    }

    &--checked {
      #{$block}__counter {
        &::before {
          content: "";
          background-color: $green;
        }

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 14px;
          height: 14px;
          transform: translate(-50%, -50%);
          background-color: $white;
          content: '';
          mask: url("../check.svg") no-repeat 50% 50%;
          display: inline-block;
        }
      }
    }
  }
}
