.input {
  $block: &;

  position: relative;
  width: 100%;
  display: block;

  &--icon {
    #{$block} {
      &__element {
        padding-left: 40px;
      }
    }
  }

  &__element {
    border: 1px solid $light-grey;
    border-radius: 2px;
    background-color: $white;
    min-width: 0;

    &:focus {
      border-color: $blue-neon;

      ~ #{$block} {
        &__icon {
          color: $green;
        }
      }
    }

    &--colored-border {
      border-color: $blue-neon;
      transition: border-color 0.2s;

      &:focus {
        border-color: $blue;
      }
    }

    &--success-border {
      border-color: $green;
      transition: border-color 0.2s;

      &:focus {
        border-color: $green;
      }
    }

    &--error {
      border-color: $red;
      transition: border-color 0.2s;

      &:focus {
        border-color: $red;
      }
    }

    &--icon {
      padding-right: 40px;
    }

    &--half {
      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }

    &:read-only {
      border-color: $light-grey;
    }
  }

  &__icon {
    position: absolute;
    top: 14px;
    left: 14px;
    transition: color 0.2s;
  }

  &__checkbox {
    $checkbox: &;

    opacity: 0;
    position: absolute;
    left: -999px;

    &-label {
      $size: 20px;

      @include small-normal($black);

      line-height: 20px;
      position: relative;
      padding: 2px 0 2px 30px;
      margin: 2px 0;
      display: block;
      cursor: pointer;
      width: 100%;

      &:before,
      &:after {
        content: " ";
        position: absolute;
        top: 2px;
        left: 0;
      }

      &:before {
        border: 1px solid $light-grey;
        display: block;
        width: $size;
        height: $size;
      }

      &:after {
        display: inline-block;
        width: $size - 4;
        height: $size - 4;
        margin: 2px;
      }

      &--sub {
        font-size: 13px;
        display: block;
        line-height: 18px;
        margin-top: 5px;
        color: rgba($dark-grey, 0.6);
      }
    }

    &:checked {
      + #{$checkbox} {
        &-label {
          &:after {
            content: "";
            mask: url("../check.svg") no-repeat 50% 50%;
            background-color: $blue;
          }
        }
      }
    }

    &:focus {
      + #{$checkbox} {
        &-label {
          &:before {
            border-color: $blue;
          }
        }
      }
    }
  }

  &__radio {
    $radio: &;

    opacity: 0;
    position: absolute;
    left: -999px;

    &-label {
      $size: 20px;

      @include small-normal($black);

      position: relative;
      padding: 2px 0 2px 30px;
      margin: 2px 0;
      display: block;
      cursor: pointer;
      width: 100%;

      &:before,
      &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: $size;
        height: $size;
      }

      &:before {
        border: 1px solid $light-grey;
        display: block;
        border-radius: 100%;
      }

      &:after {
        $isze: 12px;

        display: inline-block;
        width: $isze;
        height: $isze;
        margin: 4px;
        border-radius: 100%;
        background-color: $blue;
        opacity: 0;
        transition: opacity 0.3s;
      }
    }

    &:checked {
      + #{$radio} {
        &-label {
          &:after {
            opacity: 1;
          }
        }
      }
    }

    &:focus {
      + #{$radio} {
        &-label {
          &:before {
            border-color: $blue;
          }
        }
      }
    }
  }

  &__password-visibility {
    @include large-normal($blue);
    position: absolute;
    top: 11px;
    right: 15px;
    cursor: pointer;
  }

  &__generic-label {
    @include small-bold($black);
    font-weight: normal !important;
    font-size: 15px;
    line-height: 20px;
    width: 100% !important;
    display: block !important;
    margin-bottom: 12px !important;

    &--bold {
      font-weight: bold !important;
    }
  }

  &__error {
    $offset: 9px;

    @include small-normal($red);

    border: 1px solid $red;
    background-color: lighten($red, 45%);
    padding: 7px 10px;
    position: absolute;
    width: 100%;
    z-index: 99;
    line-height: 1.4em;
    left: 0;
    top: calc(100% + #{$offset - 6});
    border-radius: 2px;
    margin: 0;

    #{$block} &--arrow-left {
      &:before,
      &:after {
        left: 2px;
      }
    }

    &:before {
      content: " ";
      position: absolute;
      top: -$offset - 1;
      left: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px #{$offset + 1} 8px;
      border-color: transparent transparent $red transparent;
    }

    &:after {
      content: " ";
      position: absolute;
      top: -$offset + 1;
      left: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px #{$offset + 1} 8px;
      border-color: transparent transparent lighten($red, 45%) transparent;
      border-radius: 2px;
    }

    ~ #{$block} {
      background-color: #f0f;

      &__validation-icon {
        color: $red;
        position: absolute;
        top: 13px;
        pointer-events: none;
        right: 15px;
      }

      &__element {
        border-color: $red;
      }
    }

    ~ #{$block}__checkbox {

    }

    ~ #{$block}__checkbox-label {
      &:before {
        border-color: $red;
      }


      &:focus,
      &:active {
        &:before {
          border-color: $red;
        }
      }
    }
    &--always-visible {
      overflow: visible;
      white-space: normal;
    }
  }

  &--textarea {
    #{$block} {
      &__element {
        min-height: 100px;
      }
    }
  }

  &--spacing {
    margin-bottom: 10px;
  }

  &--margin-bottom {
    margin-bottom: 10px;
  }

  &--margin {
    margin: 10px 0 10px 0;
  }

  &--full-width {
    width: 100% !important;
    float: none;
    clear: both;
  }

  &--horizontal {
    display: flex;
    flex-direction: row;

    [class*="-container"] {
      width: auto;
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &--toggle {
    #{$block} {
      &__radio-container {
        border: 1px solid rgba($blue, 0.5);
        border-left: none;
        margin-left: 0;

        &:first-child {
          border-left: 1px solid rgba($blue, 0.5);
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }

      &__radio-label {
        padding-left: 0;
        padding: 15px 40px;
        min-width: 135px;
        text-align: center;
        color: $blue;
        margin: 0;
        transition: background-color 0.3s, color 0.3s;
        font-weight: bold;

        &:before,
        &:after {
          display: none;
        }
      }

      &__radio {
        &:checked {
          + label {
            background-color: $green;
            color: $white;
          }
        }
      }
    }
  }
}
