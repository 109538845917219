@keyframes spin-icon {
  0% {
    transform: rotate(45deg) translateX(0);
  }
  50% {
    transform: rotate(0deg) translateX(100%);
  }
  100% {
    transform: rotate(45deg) translateX(0);
  }
}

.loading__spinner {
  color: $blue;
  fill: currentColor;
  width: 3rem;
  height: 3rem;
  animation: spin-icon;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.54, 0.145, 0.395, 0.835);
}
