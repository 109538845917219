.select {
	position: relative;

	&__element {
		border: 1px solid $light-grey;
		border-radius: 2px;
		background-color: $white;
		font-size: 16px;
		line-height: 20px;
		padding: 12px 30px 12px 12px;

		&--colored-border {
			border-color: $blue-neon;
		}

		&:hover,
		&:focus {
			border-color: darken($light-grey, 10%);
		}
	}

	&__arrow {
		position: absolute;
		top: 15px;
		right: 15px;
    pointer-events: none;
	}
}
