.toptask {
	$block: &;
	$height: 150px;

	width: 100%;
	position: relative;
	height: $height;
	border-radius: 20px;
	color: $white;
	margin: 0 0 15px 0;
	cursor: pointer;
	flex-direction: column;
	text-decoration: none;
	transition: background-color 0.3s;
	z-index: 1;

	@include media-breakpoint-up(md) {
		margin: -15px 0 20px 0;
	}

	&__icon {
		font-size: 30px;
		margin-bottom: 25px;
		text-decoration: none;
	}

	&__title {
		@include medium-normal($white);

		display: block;
		width: 100%;
		text-align: center;
		text-decoration: none;
	}

	&__menu {
		$offset: 20px;

		position: absolute;
		top: $offset;
		left: 0;
		width: 100%;
		border-radius: 0 0 20px 20px;
		z-index: 2;
		max-height: 0;
		transition: max-height 0.3s, background-color 0.3s;
		overflow: hidden;

		&-close {
			height: $height - $offset;
			flex-direction: column;

			&-icon {
				font-size: 35px;
				transform: translateY(-10px);
			}

			&-label {
				transform: translateY(-13px);
			}
		}

		&-list {
			padding: 0 15px;
			text-align: center;
			margin-top: 0;
			padding-bottom: 20px;

			span,
			a {
				color: $white;
			}
		}
	}

	&:focus,
	&:active {
		text-decoration: none;

		#{$block} {
			&__title {
				text-decoration: underline;
			}
		}
	}

	@mixin toptask-color($color, $class) {
		background-color: $color;

		&:hover {
			background-color: darken($color, 10%);

			#{$class} {
				&__menu {
					background-color: darken($color, 10%);
				}
			}
		}
	}

	&--green {
		@include toptask-color($green, $block);
	}

	&--brown-dark {
		@include toptask-color($brown, $block);
	}

	&--brown-light {
		@include toptask-color(lighten($brown, 10%), $block);
	}

	&--blue-dark {
		@include toptask-color(lighten($blue, 20%), $block);
	}

	&--blue-light {
		@include toptask-color($contrast-blue, $block);
	}

	&--active {
		#{$block} {
			&__menu {
				max-height: 500px;
			}
		}
	}
}
