.info-dialog {
	@include clearfix();
	@include small-normal($black);

	overflow: hidden;
	display: block;
	padding: 0;
	max-height: 0;
	height: auto;
	transition: max-height 0.3s;
	position: relative;

	> div {
		padding: 15px;
		background-color: $contrast-grey;

		p {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 3px;
			}
		}
	}

	&__close {
		position: absolute;
		top: 20px;
		right: 10px;
		color: $blue;
		text-decoration: none;
		cursor: pointer;
	}


	&--active {
		max-height: 500px;
	}

	.info-trigger + & {
		> div {
			margin-top: 10px;
		}
	}
}
