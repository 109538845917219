.pagination {
  $block: &;

  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 15px 0 0 0;
  padding: 0;
  width: 100%;
  max-width: 150px;

  @include media-breakpoint-up(md) {
    margin: 0;
  }

  &__item {
    color: $grey;
    margin: 4px 0;
    padding: 0 5px;
    cursor: pointer;
    transition: color 0.3s;

    & > button {
      color: $grey;
    }

    @include media-breakpoint-up(md) {
      margin: 14px 0;
    }

    &:focus,
    &:active {
      &, & > button {
        color: darken($grey, 15%);
      }
    }

    &:hover {
      &, & > button {
        color: $blue;
      }
    }

    &--active {
      &, & > button {
        color: $dark-grey;
      }

      #{$block} {
        &__link {
          color: $dark-grey;
        }
      }
    }
  }

  &__link {
    text-decoration: none;
    color: $grey;

    &:focus,
    &:active {
      color: darken($grey, 15%);
    }

    &:hover {
      color: $blue;
    }
  }
}
