// Colors
$light-blue:		#E7F0F9;
$contrast-blue:		#ACCBE1;
$blue: 				#180D55;
$beige:             #F0EBE3;
$blue-neon:			#9CB1E1;
$grey-blue:			#E9EBF1;
$green:				#7fce6c;
$green-highlight:   #ccf6c2;
$red:				#DA2C26;
$brown:				#937D68;

// Grey
$dark-grey:			#333333;
$grey:				#999999;
$light-grey:		#CCCCCC;
$contrast-grey:		#F5F5F5;

// Generic
$black:				#000;
$white:				#FFF;
