.list {
  $list: &;

  span {
    font-weight: bold;
  }

  list-style: none;
  padding-left: 0;
  margin: 20px 0;

  &__item {
    @include clearfix();

    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &__invisible-field {
    display: none;
  }

  &__nested-data-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    float: left;

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    &-item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__form-totals {
    background-color: $light-blue;
    padding: 20px;

    &-select {
      select {
        font-size: 15px;
      }
    }

    &-price,
    &-note {
      display: block;
    }

    &-price {
      font-size: 30px;
      color: $blue;
      margin-top: 10px;

      @include media-breakpoint-up(md) {
        margin-top: 5px;
      }
    }

    &-note {
      color: $blue;
      margin-top: 15px;
    }
  }

  &--data {
    display: inline-block;
    width: 100%;
    padding-bottom: 60px;
    margin-bottom: 0;

    + #{$list} {
      &--data {
        margin-top: 0;
      }
    }

    #{$list} {
      &__item {
        margin-bottom: 15px;

        @include media-breakpoint-up(md) {
          margin-bottom: 20px;
        }

        > span,
        > label {
          float: left;
          width: 100%;
          display: inline-block;
          margin-bottom: 5px;

          @include media-breakpoint-up(md) {
            width: 50%;
            margin-bottom: 0;
          }
        }

        > label {
          margin-bottom: 8px;

          @include media-breakpoint-up(md) {
            float: left;
            padding: 12px 0;
            margin-bottom: 0;
          }
        }

        > .input,
        > .select {
          @include media-breakpoint-up(md) {
            float: left;
            width: 50%;
          }
        }

        .input {
          &__checkbox {
            &-label {
              font-size: 15px;
            }

            &-container {
              margin: 10px 0;

              &:first-child {
                margin-top: 0;
                overflow: hidden;

                @include media-breakpoint-up(md) {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }

    &-small-form {
      #{$list} {
        &__item {
          > label {
            @include media-breakpoint-up(md) {
              width: 36% !important;
            }
          }

          > .input,
          > .select {
            @include media-breakpoint-up(md) {
              width: 64% !important;
            }
          }

          &--full-width {
            > .input,
            > .select,
            > label {
              @include media-breakpoint-up(md) {
                float: none;
                clear: both;
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }

  &--non-breaking {
    padding-bottom: 0;
  }

  &--bullet {
    #{$list} {
      &__item {
        padding-left: 20px;
        position: relative;

        &:before {
          $bulletSize: 6px;

          content: " ";
          position: absolute;
          top: 8px;
          left: 2px;
          width: $bulletSize;
          height: $bulletSize;
          background-color: $blue;
          border-radius: 100%;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &--half {
      width: 50%;
    }
  }

  &--counter {
    margin: 0;
    counter-reset: count;

    > #{$list} {
      &__item {
        counter-increment: count;
        display: flex;

        &:before {
          display: inline-block;
          padding-right: 10px;
          content: counter(count) ".";
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &.white-space-nowrap {
      line-height: 1.30;

      .value {
        left: 50%;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        position: absolute;
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
          width: 150%;
        }
      }

      &.small .value {
        width: 40%;

        @include media-breakpoint-up(lg) {
          width: 50%;
        }
      }
    }
  }
}
