.accordion {
	$block: &;
	$margin: 10px;

	background-color: $grey-blue;
	margin-bottom: $margin;
	border-radius: 15px;
	padding: 15px;

  &:focus, &:focus-within {
    outline: none;
  }

	@include media-breakpoint-up(md) {
		padding: 25px;
		margin-bottom: $margin * 1.5;
	}

	&__trigger {
		$trigger: &;

		position: relative;
		cursor: pointer;
		flex-direction: column;
		justify-content: start;

		@include media-breakpoint-up(md) {
			flex-direction: row;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			margin: 0;
		}

		&-title {
			@include small-normal($blue);

			font-size: 16px;
			width: 100%;
			text-align: left;

			@include media-breakpoint-up(md) {
				@include large-bold($blue);

				width: auto;
			}
		}

    &-subtitle {
      @include small-normal($blue);

      font-size: 12px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }

		&-info {
			@include small-normal($black);

			font-size: 12px;
			padding-top: 5px;

			@include media-breakpoint-up(md) {
				@include medium-normal($blue);

				display: block;
				padding-top: 5px;
			}
		}

		&--disabled {
			color: $grey !important;

			#{$trigger} {
				&-count {
					background-color: $grey !important;
				}
			}
		}

		&--checked {
			#{$trigger} {
				&-count {
					background-color: $green !important;
					color: $green !important;

					&:after {
						position: absolute;
						top: 50%;
						left: 50%;
            width: 14px;
            height: 14px;
						transform: translate(-50%, -50%);
						background-color: $white;
            content: '';
            mask: url("../check.svg") no-repeat 50% 50%;
						display: inline-block;
					}
				}
			}
		}
	}

	&__content {
		max-height: 0;
		transition: max-height 0.3s;
		overflow: hidden;
    outline: none;

		[class*='row'] {
			> [class*='col-'] {
				> .list {
					&:not(.list--data-small-form) {
						margin-top: 40px;
					}
				}
			}
		}
	}

	&__arrow {
		font-size: 20px;
		margin-left: 15px;
		transform: translateY(-10px);
		transition: transform 0.3s;

		@include media-breakpoint-up(md) {
			transform: translateY(5px);
			margin-left: 20px;
		}
	}

	&__info-container {
		width: 100%;
		padding-top: 5px;
		justify-content: space-between;

		@include media-breakpoint-up(md) {
			padding-top: 0;
			width: auto;
			margin-right: 10px;
		}
	}

	&__exclamation {
		color: $red;
		margin-left: 5px;
	}

	&__button-container {
		@include media-breakpoint-up(md) {
			margin-top: 20px;
		}
	}

	&__text {
		margin-top: 0;
	}

	&--form {
		$size: 30px;

		background-color: rgba(0, 0, 0, 0);
		padding: 0;
		margin: 0 0 0 -10px;

		@include media-breakpoint-up(md) {
			margin: 0;
		}

		#{$block} {
			&__trigger {
				@include small-bold($blue);

				@include media-breakpoint-up(md) {
					@include medium-bold($blue);
				}

				&-count {
					@include small-normal($white);

					position: relative;
					width: $size;
					height: $size;
					background-color: $blue;
					display: inline-block;
					border-radius: 100%;
					text-align: center;
					line-height: $size;
					margin-right: 10px;

					@include media-breakpoint-up(md) {
						@include medium-normal($white);
					}
				}
			}

			&__content {
				padding: 10px 0 20px floor($size * 1.5);
				position: relative;

				&:before {
					$padding: 6px;

					content: " ";
					width: 1px;
					height: calc(100% - #{$padding * 2});
					background-color: $light-grey;
					position: absolute;
					top: $padding;
					left: $size / 2;
					transform: translateX(-50%);
				}
			}
		}

		&:last-child {
			#{$block} {
				&__content {
					padding-bottom: 5px;

					&:before {
						display: none;
					}
				}
			}
		}
	}

  &.accordeon--active,
	&--active,
  &[data-state="open"]{
		> #{$block} {
			&__content {
				max-height: 4000px;
			}
		}

		> #{$block} {
			&__trigger {
				#{$block} {
					&__arrow {
						transform: translateY(-10px) rotate(180deg);

						@include media-breakpoint-up(md) {
							transform: translateY(5px) rotate(180deg);
						}
					}
				}
			}
		}
	}

	#{$block} {
		border: 1px solid $light-grey;

		#{$block} {
			&__info-container {
				padding: 0;
			}

			&__trigger-info {
				display: none;
			}

			&__arrow {
				position: absolute;
				top: 8px;
				right: 10px;

				@include media-breakpoint-up(md) {
					top: 0;
				}
			}
		}

		&:first-child {
			margin-top: $margin * 2;

			@include media-breakpoint-up(md) {
				margin-top: $margin * 2 * 1.5;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&:first-child {
		margin-top: $margin;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
	}

	> button {
		appearance: none;
	    background: rgba(0, 0, 0, 0);
	    border: none;
	    box-shadow: none;

	    &:hover,
	    &:active,
	    &:focus {
	    	outline: none;
	    }
	}
}
