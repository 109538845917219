.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 20;
	opacity: 0;
	transform: translateX(100%);
	transition: opacity 0.3s, transform 0s 0.3s;
	max-height: 100%;
	overflow: auto;

	&__content {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translate(0, -50%);
		max-height: 100vh;
		padding-top: 40px;
	}

	&--active {
		opacity: 1;
		transform: translateX(0);
		transition: opacity 0.3s, transform 0s 0s !important;
	}
}