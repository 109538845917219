// Small
@mixin small-lighter($color) {
	color: $color;
	font-size: 14px;
	font-weight: lighter;
}

@mixin small-normal($color) {
	color: $color;
	font-size: 14px;
	font-weight: normal;
}

@mixin small-bold($color) {
	color: $color;
	font-size: 14px;
	font-weight: bold;
}

// Medium
@mixin medium-normal($color) {
	color: $color;
	font-size: 17px;
	font-weight: normal;
}

@mixin medium-bold($color) {
	color: $color;
	font-size: 17px;
	font-weight: bold;
}

@mixin medium-semi-bold($color) {
	color: $color;
	font-size: 16px;
	font-weight: 600;
}

// Large
@mixin large-normal($color) {
	color: $color;
	font-size: 20px;
	font-weight: normal;
}

@mixin large-bold($color) {
	color: $color;
	font-size: 20px;
	font-weight: bold;
}


// Huge
@mixin huge-normal($color) {
	color: $color;
	font-size: 24px;
	font-weight: normal;
}

@mixin huge-bold($color) {
	color: $color;
	font-size: 24px;
	font-weight: bold;
}
