a {
	&:focus,
	&:active {
		outline: none;
	}

	&:focus {
		text-decoration: underline;
	}
}
