p.info {
  margin-top: 0;
  margin-bottom: 30px;

  svg {
    color: $blue;
    margin-bottom: 4px;
    margin-right: 10px;
  }
}
